console.log("JS works.");


var template = document.body.dataset.intendedTemplate;

function onScroll(event) {
  let y = window.scrollY;
  // document.body.style.setProperty('--y', `${y}px`);
}
if(template=="home"){
  window.addEventListener('scroll', onScroll, { capture: false, passive: true });
}

var readmores = document.querySelectorAll('.readmore');
readmores.forEach(readmore => {
  readmore.onclick = () => {
    readmore.previousElementSibling.classList.toggle("visible");
    readmore.textContent = readmore.textContent == "En savoir +" ? "Masquer les détails" : "En savoir +";
  };
});


var authors = document.querySelectorAll('.author');

authors.forEach( g => {
  g.addEventListener('click', (e) => {
    openAuthor(g);
  });
});


function openAuthor(author) {
  if(author.classList.contains("visible-author")){
    return false;
  }
  const opend = document.querySelector('.visible-author');
  if(opend) opend.classList.remove('visible-author');
  const close = document.querySelector('.close-author');
  if(close) close.parentElement.removeChild(close);
  author.classList.add('visible-author');
  author.scrollIntoView({behavior: "smooth"});
  createClose(author);
  window.location.hash = "#" + author.id;
}


function createClose(author){
  var close = document.createElement('span');
  close.textContent = "×";
  close.className = "close-author";
  author.querySelector('.bio').appendChild(close);
  close.onclick = (e) => {
    e.stopPropagation();
    author.classList.remove('visible-author');
    close.parentElement.removeChild(close);
    window.location.hash = "";
  };
}

// rafraichissement de page
if(window.location.hash) {
  let hash = window.location.hash;
  var author = document.querySelector(hash);
  if(author){
    if(author.classList.contains('author')){
      openAuthor(author);
    }
  }
}

doMap = function(){
  
  var pau = [ 43.297539, -0.374640 ];
  var laruns = [ 42.988021, -0.426740 ];
  var zoom = 3;
  var map = L.map('map_canvas', {
    attributionControl:false,
    scrollWheelZoom: false,
    gestureHandling: true
  }).setView(pau, zoom);
  // var layer = new L.StamenTileLayer("toner");
  // map.addLayer(layer);
  var arrow = L.icon({
      iconUrl: '../assets/images/eln.svg',
      iconSize:     [140, 70], // size of the icon
      iconAnchor:   [128, 59], // point of the icon which will correspond to marker's location
  });
  var marker = L.marker(laruns, {icon: arrow}).addTo(map);
  // marker.bindPopup('You might be here…');
  marker.addEventListener('click', function(){
    map.setView(laruns, 16);
    // map.panTo([ 46.302618, 2.399459 ])
  });
  var Esri_WorldTopoMap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
	attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
});
Esri_WorldTopoMap.addTo(map);
};

if(template == "infos"){
  doMap();
}